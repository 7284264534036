@charset "UTF-8";


/*Header Bottom Area*/
.header-bottom {
  /* background: transparent; */
  position: relative;
  box-shadow: 0 3.4px 2.7px rgb(0 0 0 / 1%), 0 8.7px 6.9px rgb(0 0 0 / 1%), 0 17.7px 14.2px rgb(0 0 0 / 1%), 0 36.5px 29.2px rgb(0 0 0 / 1%), 0 100px 80px rgb(0 0 0 / 2%);
}


.piara {
  background-color: rgba(255, 255, 255, 1);
  border-radius: 15px;
  z-index: 1;
}



.piaralogin {
  background-color: white;
  filter: drop-shadow(0 0 0.5rem #d8d8d8);
  border-radius: 15px;
  margin-right: auto;
  margin-left: auto;
  max-width: 700px;
  padding-bottom: 60px;
  margin-top: 60px;
}



.sticky-header.sticky {
  position: fixed;
  z-index: 99;
  width: 100%;
  top: 0;
  /* box-shadow: 0 0 2px rgba(0, 0, 0, 0.1); */
  animation-name: fadeInDown;
  animation-duration: 900ms;
  animation-timing-function: cubic-bezier(0.2, 1, 0.22, 1);
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  border-bottom: 0;
}

.main-menu {
  position: relative;
}

.main-menu nav>ul>li {
  position: relative;
  display: inline-block;
}

.main-menu nav>ul>.has-dropdown {
  margin-right: 5px;
  z-index: 4;
}

.main-menu nav>ul>.has-dropdown:last-child {
  margin-right: 0;
}

.main-menu nav>ul>.has-megaitem {
  position: static;
}

.main-menu nav>ul>li>a {
  display: block;
  line-height: 24px;
  padding: 15px 30px;
  font-size: 16px;
  line-height: 30px;
  text-transform: capitalize;
  font-weight: 500;
  color: #000000;
}

.main-menu nav>ul>li>a:hover,
.main-menu nav>ul>li>a.active {
  background: #efefef;
  color: #000;
}

.sub-menu>li>a,
.mega-menu-sub>li>a {
  font-weight: 400;
  display: block;
  line-height: 35px;
  text-transform: capitalize;
  color: #000;
  position: relative;
  transition: all 0.3s ease 0s;
}

.sub-menu>li>a::after,
.mega-menu-sub>li>a::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  transform: translate(0px, -50%);
  height: 5px;
  width: 5px;
  border-radius: 50%;
  background: #261146;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease 0s;
}

.sub-menu>li>a:hover,
.mega-menu-sub>li>a:hover {
  color: #261146;
  transform: translateX(10px);
}

.sub-menu>li>a:hover::after,
.mega-menu-sub>li>a:hover::after {
  transform: translate(-10px, -50%);
  visibility: visible;
  opacity: 1;
}

.sub-menu {
  position: absolute;
  min-width: 230px;
  padding: 25px 20px;
  background: #FFF;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-20deg);
  transform-origin: 0 0 0;
  left: 0;
  right: auto;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  top: 100%;
  text-align: left;
}

.mega-menu {
  position: absolute;
  min-width: 100%;
  padding: 25px 30px 30px 30px;
  background: #FFF;
  box-shadow: 0 3px 11px 0 rgba(0, 0, 0, 0.1);
  transform: perspective(600px) rotateX(-20deg);
  transform-origin: 0 0 0;
  left: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s;
  z-index: 99;
  top: 100%;
  text-align: left;
}

.mega-menu-inner {
  display: flex;
  justify-content: space-between;
}

.mega-menu-item-title {
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  font-weight: 700;
  display: block;
  margin-bottom: 13px;
  color: #333;
}

.mega-menu-item-title:hover {
  color: #261146;
}

.mega-menu-sub>li {
  display: block;
}

.main-menu nav>ul>li:hover .sub-menu,
.main-menu nav>ul>li:hover .mega-menu {
  opacity: 1;
  visibility: visible;
  transform: perspective(600px) rotateX(0deg);
}

.menu-banner {
  margin-top: 20px;
}

.menu-banner>a {
  display: block;
  width: 100%;
}

.menu-banner img {
  width: 100%;
}

.mobile-header-wrapper {
  padding-top: 25px;
  padding-bottom: 25px;
}

.mobile-menu {
  border: 1px solid #000;
  padding: 5px;
  display: block;
  border-radius: 4px;
}

.mobile-menu-dash {
  display: block;
  width: 30px;
  height: 2px;
  background: #000;
  margin-top: 5px;
  margin-bottom: 5px;
}

/*****************************
Layout - Offcanvas
*****************************/
.offcanvas {
  z-index: 999999;
  position: fixed;
  top: 0;
  overflow: auto;
  height: 100vh;
  padding: 20px;
  transition: all 0.3s ease 0s;
  transform: translateX(100%);
  box-shadow: none;
  background: rgba(37, 47, 53, 0.95);
  backdrop-filter: blur(18px);
  color: #eaeaea;
  width: 300px;
  overflow-x: hidden;
  box-shadow: 0 3.4px 2.7px rgb(0 0 0 / 1%), 0 8.7px 6.9px rgb(0 0 0 / 1%), 0 17.7px 14.2px rgb(0 0 0 / 1%), 0 36.5px 29.2px rgb(0 0 0 / 1%), 0 100px 80px rgb(0 0 0 / 2%);
}

.offcanvas-leftside {
  left: 0%;
  transform: translateX(-100%);
}

.offcanvas-rightside {
  right: 0%;
  transform: translateX(100%);
}

.offcanvas.offcanvas-open {
  transform: translateX(0);
}

.offcanvas-overlay {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
}

.offcanvas-header {
  margin-bottom: 30px;
}

.offcanvas-close {
  width: 28px;
  height: 28px;
  line-height: 28px;
  text-align: center;
  background: #fff;
  border-radius: 50%;
  transition: all 0.3s ease 0s;
}

.offcanvas-close:hover {
  color: #261146;
  border-color: #261146;
}

.offcanvas-title {
  margin-bottom: 40px;
}

/*mobile-menu Top Area*/
.mobile-menu-top {
  font-size: 13px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.mobile-menu-user-menu {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  justify-content: center;
}

.mobile-menu-user-menu>li {
  margin-right: 40px;
  position: relative;
}

.mobile-menu-user-menu>li:last-child {
  margin-right: 0;
}

.mobile-menu-user-menu>li::after {
  position: absolute;
  content: "|";
  top: 50%;
  transform: translateY(-50%);
  right: calc(0% - 20px);
}

.mobile-menu-user-menu>li:last-child::after {
  display: none;
}

.mobile-menu-user-menu>li>a {
  display: inline-block;
  line-height: 46px;
  color: #666 !important;
}

.mobile-menu-user-menu>li>a:hover {
  color: #261146;
}

.has-mobile-user-dropdown {
  position: relative;
  padding-right: 15px;
}

.has-mobile-user-dropdown::before {
  content: "";
  font-family: "FontAwesome";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-weight: 400;
}

.mobile-user-sub-menu {
  position: absolute;
  top: calc(100%);
  left: 50%;
  transform: translate(-50%, -30px);
  padding: 10px 15px;
  width: 160px;
  background: #FFF;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}

.mobile-user-sub-menu>li {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 5px 0;
}

.mobile-user-sub-menu>li:last-child {
  border-bottom: none;
}

.mobile-user-sub-menu>li>a {
  position: relative;
  color: #666;
  display: flex;
  align-items: center;
}

.mobile-user-sub-menu>li>a:hover {
  color: #261146;
}

.user-sub-menu-link-icon {
  padding-right: 5px;
}

.has-mobile-user-dropdown:hover .mobile-user-sub-menu {
  visibility: visible;
  opacity: 1;
  transform: translate(-50%, 0);
}

/*Mobile Menu Center Area*/
.mobile-menu-customer-support {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Raleway", sans-serif;
}

.mobile-menu-customer-support-icon {
  max-width: 50px;
  overflow: hidden;
  margin-right: 30px;
}

.mobile-menu-customer-support-icon img {
  width: 100%;
}

.mobile-menu-customer-support-text-phone {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: #000;
  transition: all 0.3s ease 0s;
}

.mobile-menu-customer-support-text-phone:hover {
  color: #261146;
}

.mobile-menu-customer-support-text {
  font-size: 14px;
  margin: 20px 0;
}

.mobile-action-icon {
  display: flex;
  justify-content: center;
}

.mobile-action-icon-item {
  margin-right: 40px;
}

.mobile-action-icon-item:last-child {
  margin-right: 0;
}

.mobile-action-icon-link {
  position: relative;
  font-size: 27px;
  color: #333;
  transition: all 0.3s ease 0s;
}

.mobile-action-icon-link:hover {
  color: #261146;
}

.mobile-action-icon-item-count {
  position: absolute;
  top: 0;
  right: calc(0% - 10px);
  font-size: 11px;
  display: inline-block;
  background: #333;
  color: #FFF;
  text-align: center;
  line-height: 20px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  transition: all 0.3s ease 0s;
}

.mobile-action-icon-link:hover .mobile-action-icon-item-count {
  background: #261146;
}

/*Mobile Menu Bottom Area*/
.mobile-menu-bottom {
  position: fixed;
  bottom: 5%;
}

.offcanvas-menu {
  margin: 20px 0;
}

.offcanvas-menu li {
  position: relative;
  padding: 5px 5px;
}

.offcanvas-menu>ul>li {
  border-bottom: 1px solid transparent;
}

.offcanvas-menu>ul>li:last-child {
  border-bottom: none;
}

.offcanvas-menu li a {
  display: block;
  color: #eaeaea;
  text-decoration: none;
}

.offcanvas-menu li a:hover {
  color: #ddd;
}

.offcanvas-menu li .mobile-sub-menu {
  display: none;
}

.offcanvas-menu-expand::after {
  content: "";
  position: absolute;
  font-family: "FontAwesome";
  right: 0;
  transition: all 0.3s ease;
  color: #666;
}

.offcanvas-menu .active>.offcanvas-menu-expand:last-child,
.sub-menu>.active .offcanvas-menu-expand:last-child {
  border-bottom: none;
}

.offcanvas-menu .active>.offcanvas-menu-expand::after,
.sub-menu>.active .offcanvas-menu-expand::after {
  color: #777;
  content: "";
}

.mobile-menu-email {
  color: #999;
  text-align: center;
  display: block;
}

.mobile-menu-email:hover {
  color: #261146;
}

.mobile-menu-social {
  margin-top: 25px;
  text-align: center;
}

.mobile-menu-social>li {
  display: inline-block;
  margin-right: 13px;
}

.mobile-menu-social>li:last-child {
  margin-right: 0px;
}

.mobile-menu-social>li>a {
  display: inline-block;
  font-size: 14px;
  padding: 0;
  line-height: 30px;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  text-align: center;
  color: #FFF !important;
}

.mobile-menu-social>li>a:hover {
  opacity: 0.7;
}

.mobile-menu-social>li>a.facebook {
  background: #4867AA;
}

.mobile-menu-social>li>a.twitter {
  background: #1DA1F2;
}

.mobile-menu-social>li>a.youtube {
  background: #FE0000;
}

.mobile-menu-social>li>a.pinterest {
  background: #BD081B;
}

.mobile-menu-social>li>a.instagram {
  background: #B23A94;
}

.offcanvas-cart-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.offcanvas-cart-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-cart-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-cart-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #eee;
  margin-right: 20px;
}

.offcanvas-cart-image {
  width: 100%;
}

.offcanvas-cart-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-cart-item-details {
  font-family: "Raleway", sans-serif;
}

.offcanvas-cart-total-price {
  margin-top: 40px;
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-family: "Raleway", sans-serif;
}

.offcanvas-cart-total-price-text {
  font-weight: 700;
}

.offcanvas-cart-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-cart-action-button-link {
  display: block;
  background: #000;
  color: #FFF;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
}

.offcanvas-cart-action-button-link:hover {
  background: #261146;
  color: #FFF;
}

.offcanvas-wishlist-item-single {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.offcanvas-wishlist-item-single:last-child {
  margin-bottom: 0;
}

.offcanvas-wishlist-item-block {
  display: flex;
  align-items: center;
}

.offcanvas-wishlist-item-image-link {
  width: 90px;
  overflow: hidden;
  border: 1px solid #eee;
  margin-right: 20px;
}

.offcanvas-wishlist-image {
  width: 100%;
}

.offcanvas-wishlist-item-link {
  display: block;
  font-weight: 700;
}

.offcanvas-wishlist-item-details {
  font-family: "Raleway", sans-serif;
}

.offcanvas-wishlist-total-price-text {
  font-weight: 700;
}

.offcanvas-wishlist-action-button {
  margin-top: 40px;
}

.offcanvas-wishlist-action-button-list {
  margin-bottom: 25px;
  display: block;
}

.offcanvas-wishlist-action-button-link {
  display: block;
  background: #000;
  color: #FFF;
  padding: 10px 30px;
  text-align: center;
  font-weight: 600;
  text-transform: capitalize;
}

.offcanvas-wishlist-action-button-link:hover {
  background: #261146;
  color: #FFF;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.col-md-4 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
}

@media (min-width: 576px) {
  .col-md-4 {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 768px) {

  .col-md-4 {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 992px) {
  .col-md-4 {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
  }
}

@media (min-width: 1200px) {
  .col-md-4 {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
  }
}

.card-block {
  flex: 1 1 auto;
  padding: 1.25rem;
}

.card-title {
  margin-bottom: 0.75rem;
}

.card-subtitle {
  margin-top: -0.375rem;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.65);
}

.text-muted {
  color: #636c72 !important;
}

a.text-muted:focus,
a.text-muted:hover {
  color: #4b5257 !important;
}


.card-text {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.65);
}

.card-link:hover {
  text-decoration: dashed;
  color:#0835c9;
}

.card-link {
  color: #00b0eb;
  font-weight: 550;
  text-decoration: dashed;
}

.button {
  background-color: #007BFF;
  color: #fff;
  width: 400px;
  height: 42px;
  margin-top: 18px;
  border-radius: 5px;
  font-size: 16px;
  outline: none;
  border: none;
}
.button:focus, .login-inp:focus {
  outline: none;
}


.login-inp {
  width: 400px;
  height: 40px;
  font-weight: 400;
  border: 1px solid #444444;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 5px;
  justify-content: center;
  margin-top: 15px;
}

@media (max-width: 460px) {
  .button {
  width: 300px;
  height: 40px;
  margin-top: 14px;
  border-radius: 5px;
  font-size: 15px;
  outline: none;
  border: none;
  }
  .login-inp {
  width: 300px;
  height: 40px;
  border: 1px solid #444444;
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  justify-content: center;
  margin-top: 12px;
  }
}

.txtc {
  font-size: 16px;
  color: black;

}

.createacc {
  background-color: #FFFFFF;
  border: 1px solid #00701a;
  color: #00701a;
  margin-top: 1px;
}

.lantibar {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center; 
}

.alignri {
  /* align-self: flex-end;  */
  /* padding-right: 15px; */
  /* padding-left: 15px; */
  margin-left: 7px;
  
}

.alignlf {
margin-left: 7px;
}
@media (max-width: 991.98px) {
  .lantibar {
    flex-direction: column;
  }
}

.colredd {
  color: rgb(255, 80, 80);
}

.colredd:hover {
  color: red;
}

@media (max-width: 325px) {
  .vsmallwid {
    width: 200px;
    height: 30px;
  }
}


/* _____________________________________________________________________ */

.pricingTable {
  color: #9b9b9d;
  background: repeating-linear-gradient(45deg,#fff,#fff 20px,#fcfcfc 20px,#fcfcfc 40px);
  font-family: 'Open Sans', sans-serif;
  text-align: center;
  padding: 45px 0 40px;
  border-radius: 50px/80px;
  box-shadow: 0 0 15px -2px rgba(0,0,0,0.2);
  outline: 4px solid #0073D4;
  outline-offset: -12px;
  position: relative;
  overflow: hidden;
}
.pricingTable:before{
  content: "";
  background: #0073D4;
  width: 150px;
  height: 50px;
  border: 10px solid #fff;
  border-radius: 50px 50px;
  transform: translateX(-50%) translateY(0);
  position: absolute;
  top: -25px;
  left: 50%;
}
.pricingTable .title{
  font-size: 30px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0 0 25px;
}
.pricingTable .price-value{
  color: #fff;
  background: #0073D4;
  padding: 12px 50px;
  margin: 0 0 25px;
  border-radius: 50px;
  display: inline-block;
}
.pricingTable .price-value .amount{
  font-size: 35px;
  line-height: 35px;
  font-weight: 600;
  margin: 0 0 3px;
  display: block;
}
.pricingTable .price-value .duration{
  font-size: 14px;
  line-height: 14px;
  display: block;
}
.pricingTable .pricing-content{
  padding: 0;
  margin: 0 0 25px;
  list-style: none;
}
.pricingTable .pricing-content li{
  font-size: 17px;
  font-weight: 500;
  line-height: 30px;
  margin: 0 0 12px;
}
.pricingTable .pricing-content li:last-child{ margin-bottom: 0; }
.pricingTable .pricingTable-signup a{
  background: #0073D4;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 6px 15px;
  border-radius: 50px;
  display: inline-block;
  transition: all 0.3s;
}
.pricingTable .pricingTable-signup a:hover{
  text-shadow: 3px 3px 3px rgba(0, 0, 0, 0.6);
  box-shadow: 5px 5px rgba(0,0,0,0.2);
}
.pricingTable.blue{ outline-color: #28B9C1; }
.pricingTable.blue:before,
.pricingTable.blue .price-value,
.pricingTable.blue .pricingTable-signup a{
  background-color: #28B9C1;
}
.pricingTable.green{ outline-color: #3EA394; }
.pricingTable.green:before,
.pricingTable.green .price-value,
.pricingTable.green .pricingTable-signup a{
  background-color: #3EA394;
}
@media only screen and (max-width: 990px){
  .pricingTable{ margin: 0 0 40px; }
}
.green {
  color: green
}
.red {
  color: red;
}

/* _______________________________________________________ */



.modal {
  font-size: 12px;
  width: 100%;
}
.modal > .header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal > .content {
  width: 100%;
  padding: 10px 5px;
}
.modal > .actions {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.cursorpointer-1 {
  cursor: pointer;
}
.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff;
  border-radius: 18px;
  border: 1px solid #cfcece;
}



.container01 {
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe01 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
/* 
____________________________________________________________ */

.faqs {
	width: 100%;
	max-width: 768px;
	margin: 0 auto;
	padding: 15px;
}

.faqs .faq {
	margin: 15px;
	padding: 15px;
	background-color: #FFF;
	border-radius: 8px;
  border: 1px solid rgba(226, 128, 0, 0.945);
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}

.faqs .faq .faq-question {
	position: relative;
	font-size: 16px;
	transition: all 0.4s ease;
}

.faqs .faq .faq-question::after {
	content: '';
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	width: 30px;
	height: 30px;

	/* background-image: url('./arrow-down-mint.svg'); */
	background-position: center;
	background-size: contain;
	background-repeat: no-repeat;

	transition: all 0.4s ease-out;
}

.faqs .faq .faq-answer {
	opacity: 0;
	max-height: 0;
	overflow-y: hidden;
	transition: all 0.4s ease-out;
}

.faqs .faq.open .faq-question {
	margin-bottom: 15px;
}

.faqs .faq.open .faq-question::after {
	transform: translateY(-50%) rotate(180deg);
}

.faqs .faq.open .faq-answer {
	max-height: 1000px;
	opacity: 1;
}

/* Snackbar Styles: _______________________________________________________ */

.centered{
	position:absolute;
	left:50%;
	top:50%;
	transform:translate(-50%,-50%);
}
.notification{
	position:fixed;
	top:150%;
	left:5%;
	background:#333;
	padding:15px;
	color:#fff;
	font-size:16px;
	box-shadow:0 0 25px rgba(33,33,33,0.5);
	border-radius:3px;
	transform:translateY(-50%);
	box-sizing:border-box;
	animation:0.3s down ease 1; 
	opacity:0;
}
.notification.active{
	top:90%;
	left:5%;
	opacity:1;
	animation:0.3s up ease 1;
	display:block;
}
.notification a{
	color: skyblue;
	text-decoration:none;
	display:block;
	float:right;
	width:auto;
	padding:0 10px;
	height:40px;
	margin-left:15px;
	background:rgba(0,0,0,0.1);
	border-radius:5px;
}
.text{
	height:40px;
	line-height:40px;
	padding:0 10px;
	text-align:center;
	float:left;
}
.close-snack{
	height:40px;
	width:40px;
	line-height:40px;
	border-radius:50%;
	text-align:center;
	position:relative;
	cursor:pointer;
	overflow:hidden;
	float:right;
	background:rgba(0,0,0,0.1);
	font-size:12px;
}
.ripple{
	overflow:hidden;
	position:relative;
}
.ripple::after{
	content:'';
	width:0;
	height:0;
	position:absolute;
	top:50%;
	left:50%;
	transform:translate(-50%,-50%);
	border-radius:50%;
	background:rgba(255,255,255,0.1);
	display:none;
}
.ripple.rect::after{
	border-radius:0;
}
.close-snack .text{
	padding:0;
	text-align:center;
	width:100%;
	height:100%;
}
.ripple:hover::after{
	content:'';
	display:block;
	width:100%;
	height:100%;
	animation:0.7s rip ease 1;
}
@keyframes rip{
	0%{
		width:5px;
		height:5px;
	}
	75%{
		transform:scale(4);
	}
}
@keyframes up{
	0%{
		display:none;
		top:100%;
		opacity:0;
	}
	75%{
		top:90%;
		opacity:1;
	}
	100%{
		display:block;
	}
}
@keyframes down{
	0%{
		top:90%;
		opacity:1;
		display:block;
	}
	75%{
		top:150%;
		opacity:0;
	}
	100%{
		display:block;
	}
}
@keyframes sup{
	0%{
		display:none;
		top:100%;
		transform:translateY(0%);
		opacity:0;
	}
	75%{
		top:100%;
		transform:translateY(-100%);
		opacity:1;
	}
	100%{
		display:block;
	}
}
@keyframes sdown{
	0%{
		top:100%;
		opacity:1;
		display:block;
		transform:translateY(-100%);
	}
	75%{
		top:150%;
		opacity:0;
		transform:translateY(0%);
	}
	100%{
		display:block;
	}
}
@media only screen and (max-width:560px){
	.notification,.notification.active{
		width:100%;
		top:100%;
		transform:translateY(-100%);
		left:0;
		border-radius:0;
		animation:0.3s sdown ease 1;
	}
	.notification.active{
		animation:0.3s sup ease 1;
	}
}

/* _____________________________________________________________ */

ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  /* background-color: transparent; */
  background-color: #4ba2ff;
}
ul.breadcrumb li {
  display: inline;
  font-size: 18px;
}
ul.breadcrumb li+li:before {
  padding: 8px;
  color: rgb(255, 255, 255);
  content: "/\00a0";
}
ul.breadcrumb li a {
  /* color: #0275d8; */
  color: white;
  text-decoration: none;
}
ul.breadcrumb li a:hover {
  /* color: #01447e; */
  text-decoration: underline;
}

/* ________________________ */

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: rgb(179, 27, 27);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}


.balls {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
}

.balls div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #fc2f70;
  transform: translateY(-100%);
  animation: wave 0.8s ease-in-out alternate infinite;
}

.balls div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.balls div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes wave {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(100%);
  }
}


.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
}
 
.box {
  position: relative;
  width: 70%;
  margin: 0 auto;
  height: auto;
  max-height: 70vh;
  margin-top: calc(100vh - 85vh - 20px);
  background: #fff;
  border-radius: 4px;
  padding: 20px;
  border: 1px solid #999;
  overflow: auto;
}
 
.close-icon {
  content: 'x';
  cursor: pointer;
  position: fixed;
  right: calc(15% - 30px);
  top: calc(100vh - 85vh - 33px);
  background: #ffffff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  line-height: 20px;
  text-align: center;
  border: 1px solid rgb(0, 0, 0);
  font-size: 20px;
}

.outline-none, .outline-none:focus  {
    outline: none;
}

/* _____________________________________ */

.payment
	{
		border:1px solid black;
		height:280px;
    border-radius:20px;
    background:#fff;
    filter: drop-shadow(30px 10px 4px #8d8d8d);
	}


   .payment_header
   {
	   background:green;
	   padding:20px;
       border-radius:20px 20px 0px 0px;
	   
   }
   
   .check
   {
	   margin:0px auto;
	   width:50px;
	   height:50px;
	   border-radius:100%;
	   background:#fff;
	   text-align:center;
   }
   
   .check i
   {
	   vertical-align:middle;
	   line-height:50px;
	   font-size:30px;
   }

    .content 
    {
        text-align:center;
    }

    .content  h1
    {
        font-size:25px;
        padding-top:25px;
    }

    .content-a
    {
        width:200px;
        height:35px;
        color:#fff;
        border-radius:30px;
        padding:10px 20px;
        background:rgb(185, 0, 0);
        transition:all ease-in-out 0.3s;
    }

    .content-a:hover
    {
      color: white;
      background:rgb(139, 0, 0);
    }


